<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <account-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountInfor.accountDateTitle2')}}</p>
          </div>
          <div v-if="noBankCard" class="bank-main">
            <div v-if="bankCardState" class="identity-box">
              <img class="sf-suss-img" src="@/assets/images/account/sf-cuss2-img.png" alt="">
              <p class="tips-p">{{$t('accountInfor.bindBankTitle1')}}</p>
              <div class="from-bank-bg">
                  <div class="conter-box">
                    <div class="bank-list">
                        <div class="bank-name">                    
                          <p class="bank-title">{{$t('accountInfor.bindBankTitle2')}}</p>                                          
                          <input class="bank-input-text" :value="findUserInfo.bankName" disabled="true"/>
                      </div>
                      <div class="bank-name">                    
                          <p class="bank-title">{{$t('accountInfor.bindBankTitle3')}}</p>                                          
                          <input class="bank-input-text" :value="findUserInfo.bankCode" disabled="true"/>
                      </div>
                    </div>
                    <div class="bank-list">
                      <div class="bank-name">                    
                        <p class="bank-title">{{$t('accountInfor.bindBankTitle4')}}</p>                                          
                        <input class="bank-input-text" :value="findUserInfo.bankBranch" disabled="true"/>
                      </div>
                      <div class="bank-name">                    
                        <p class="bank-title">{{$t('accountInfor.bindBankTitle5')}}</p>                                          
                        <input class="bank-input-text" :value="findUserInfo.bankCardNumber" disabled="true"/>
                      </div>
                    </div>                
                    <div class="ts-box">
                      <img src="../assets/images/register/ys-icon.png" alt="">
                      <p>{{$t('perfectData.informationConfirmTitle5')}}</p>
                    </div>  
                  </div>
                </div>             
              <button @click="modifyBankCard" class="bank-modify-btn">{{$t('perfectData.modify')}}</button>                    
            </div>

            <!-- 修改银行卡 -->
            <div v-if="!bankCardState" class="identity-box">
              <p class="tips-p">{{$t('perfectData.addBankTitle2')}}</p>
              <div class="from-box">            
                <div class="input-list">
                  <div class="bank-fr" :class="{ thStyle: isthStyle != 0 }">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput6')}}：</span>                 
                      </div>

                      <div class="c-channel-box">
                        <div @click.stop="getAllBankBy" class="c-channel">    
                          <div class="c-channel-data">
                            <p class="name dian1">{{selectBank.bankName}}</p>
                            <input type="hidden" :value="selectBank.bankCode">
                          </div>
                          <img class="xl-icon" src="@/assets/images/transaction/xl-icon.png" alt="">
                        </div>
                        <div v-if="bankChanneState" class="channer-menu scrollbarchange">
                          <div  class="c-channel" v-for="(item,index) in allBankList" :key="index" @click="selectBankChanne(item)">
                            <div class="c-channel-data">
                              <p class="name dian1">{{ item.bankName }}</p>
                              <input type="hidden" :value="item.bankCode">
                            </div>
                          </div>
                        </div> 
                      </div>
                      <p v-if="selectBankNameErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{selectBankNameErrContent}}</span></p>
                  </div> 
                  <div v-if="isthStyle == 0 " class="bank-fl">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput5')}}：</span>
                        <div class="tips-btn">
                          <img src="@/assets/images/account/wh-s-icon.png" alt="">
                          <div class="tips-box">
                            <p class="tips-text">{{$t('perfectData.example')}}：</p>
                            <ul class="yh-list-box">
                              <li><span>{{$t('perfectData.addBankInput6')}}</span><span>{{$t('perfectData.addBankInput5')}}</span></li>
                              <li><span>{{$t('perfectData.twBankName1')}}</span><span>013</span></li>
                              <li><span>{{$t('perfectData.twBankName2')}}</span><span>822</span></li>
                              <li><span>{{$t('perfectData.twBankName3')}}</span><span>700</span></li>
                            </ul>
                          </div>
                        </div>                 
                      </div>
                      <input v-model="bankCode" @blur="inputBlur(1)" class="bank-code" type="text" :placeholder="$t('perfectData.example')+'：013'">
                      <p v-if="bankCodeErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankCodeErrContent}}</span></p>
                  </div>              
              </div>
                          
              <div v-if="bankNameInput" class="bank-name-box">
                  <input v-model="bankName" @blur="inputBlur(2)" class="bank-name" type="" :placeholder="$t('perfectData.addBankInput2')">
                  <p v-if="bankNameErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankNameErrContent}}</span></p>
              </div>                                  
              
              <div class="input-list">              
                  <div class="bank-fr" :class="{ thStyle: isthStyle == 2 }">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput8')}}：</span>                 
                      </div>
                      <input v-model="bankAccount" @blur="inputBlur(3)" class="bank-account" type="text" :placeholder="$t('perfectData.example')+'：'+[isthStyle?'000-0-00000-0':'000-00-000000-0']">
                      <p v-if="bankAccountErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankAccountErrContent}}</span></p>
                  </div>
                  <div v-if="isthStyle == 0 || isthStyle == 1" class="bank-fl">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput7')}}：</span>                 
                      </div>
                      <input v-model="branchName" @blur="inputBlur(4)" class="branch-name" type="" :placeholder="$t('perfectData.example')+'：'+[isthStyle?$t('perfectData.thBankData2'):$t('perfectData.twBankData2')]">
                      <p v-if="branchNameErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{branchNameErrContent}}</span></p>
                  </div>                                  
              </div>
              <div class="upload-image-tlt">
                <div class="text-box">
                  <span>{{$t('perfectData.addBankTitle3')}}：</span>
                  <div class="tips-btn">
                    <img src="@/assets/images/account/wh-s-icon.png" alt="">
                    <div class="tips-box">
                      <p class="tips-text">{{$t('perfectData.example')}}：</p>
                      <img v-if="findUserInfo.countryId == 'Thailand'" src="@/assets/images/account/th-bank.png" alt="">
                      <img v-else-if="findUserInfo.countryId == 'Vietnam'" src="@/assets/images/account/vn-bank.png" alt="">
                      <img v-else src="@/assets/images/account/tw-bank.png" alt="">
                    </div>
                  </div>                 
                </div>
              </div>
              <div class="upload-image-content">
                <input @change="uploadBankImage" name="reverseImageFile" type="file" accept="image/png,image/gif,image/jpeg" id="uploadBank" style="display: none;"/>
                <label for="uploadBank">
                  <div class="bank-image">
                      <img v-if="bankImage" class="image-upload" :src="bankImage" />
                      <div class="upload-image-tip">
                          <p class="p2">{{$t('perfectData.addBankTitle4')}}</p>  
                      </div>
                      <img src="../assets/images/register/add-icon.png" class="image-add">                  
                  </div>
                </label> 
                <p v-if="bankImgIdErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankImgIdErrContent}}</span></p>
              </div>
                <button @click="SubmitBankCard" class="input-btn">{{$t('accountInfor.Submit')}}</button>           
              </div>                                  
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加银行卡组件 -->
      <add-bank-data :showModal="showModal"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import AccountNav from '@/components/AccountNav.vue';
import AddBankData from '@/components/AddBankData.vue';
import { getCookie } from '@/util/cookie';
import account from '@/api/account.js';
import {Config, HEADER_USER_TOKEN} from "@/util/const";
export default {
  name: "BankCardVerification",
  components: {
    Header,
    LeftMenu,
    AccountNav,
    AddBankData,
  },
  data() {
    return {
      loginState:2,
      showModal:false,
      isthStyle:0,
      findUserInfo:[],
      allBankList:[],
      bankChanneState:false,
      selectBank:{
        bankName:this.$i18n.t('loginRegister.checkPrompt27'),
        bankCode:''
      },
      selectBankOther:false,
      bankNameInput:false,
      noBankCard:false,
      bankCardState:false,
      bankImage:'',
      bankImgId:'',
      bankCode:'',
      bankName:'',
      branchName:'',
      bankAccount:'',
      bankCodeErr:false,
      bankNameErr:false,
      selectBankNameErr:false,
      branchNameErr:false,
      bankAccountErr:false,
      bankImgIdErr:false,
      selectBankNameErrContent:'',
      bankCodeErrContent:'',
      bankNameErrContent:'',
      branchNameErrContent:'',
      bankAccountErrContent:'',
      bankImgIdErrContent:'',
    }
  },
  created() {
    const that = this;
    // 查询用户基本资料
    const userToken = getCookie(HEADER_USER_TOKEN);
    console.log(userToken);
    if(!userToken){
      this.$router.push({ path: '/login' })
    }else{
      account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              that.findUserInfo = res.data.value;
              // 调用获取银行数据列表方法
              this.getAllBankByList(this.findUserInfo.countryId);
              if(res.data.value.countryId == "Thailand"){
                this.isthStyle = 1;
              }else if(res.data.value.countryId == "Indonesia" || res.data.value.countryId == "Vietnam"){                      
                this.isthStyle = 2;
              }else{
                this.isthStyle = 0;
              }             
              if(res.data.value.identityResult == 0){
                 this.$router.push({ path: '/authentication' })               
                }else{
                  console.log(res.data.value.bankCardStatus+"555");
                  if(res.data.value.bankCardStatus == 0 || res.data.value.bankCardStatus == 3){
                    that.showModal = true
                    that.bankCardState = false
                    that.noBankCard = false
                  }else{
                    that.showModal = false
                    that.bankCardState = true
                    that.noBankCard = true
                  }
                }
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }
  },
  methods: {
    // 获取银行数据列表
    getAllBankByList(countryId){
      let data = {
        countryId: countryId,
      }
      account.getAllBankByList(data).then((res)=>{
          if(res.data.success == 1){
            this.allBankList = res.data.value;
          }else{
            console.log(res.data.message);
          }
        }).catch(err=>{
          console.log(err.message);
        })
    },
    // 显示银行下拉列表
    getAllBankBy(){
      this.bankChanneState = !this.bankChanneState;
    },
    // 选择银行名称
    selectBankChanne(channel){
      this.bankChanneState = false;
      this.selectBankNameErr = false;
       this.selectBank = channel;
       this.selectBankOther = true;
      if(channel.id == "app.AllCountries.bank"){
        this.bankNameInput = true;
        this.bankName = '';
        this.bankCode = '';
      }else{
        this.bankNameInput = false;
        this.bankName = channel.bankName;
        this.bankCode = channel.bankCode;
      }     
    },
    // 上传银行账单照片
    uploadBankImage(e){
      var self = this
      let file = e.target.files[0];
      console.log(file.name+"file");
      let param = new FormData(); // 创建form对象
      param.append('file', file, file.name) // 通过append向form对象添加数据
      account.uploadBankImg(param).then((res)=>{
          if(res.data.success == 1){
              this.bankImage = Config.images + res.data.value.realFullName;
              this.bankImgId = res.data.value.id;
              this.bankImgIdErr = false;
          }else{
              console.log(res.data.message+"aaa");
          }
      }).catch(err=>{
        console.log(err.message+"bbb");
      })
    },
    // 鼠标离开输入框校验不能为空
    inputBlur(type){
      if(type == 1){
        if(!this.bankCode){
          this.bankCodeErr = true;
          this.bankCodeErrContent = this.$i18n.t('loginRegister.checkPrompt17');          
        }else{
          this.bankCodeErr = false;
        }
      }
      if(type == 2){
        if(!this.bankName){
          this.bankNameErr = true;
          this.bankNameErrContent = this.$i18n.t('loginRegister.checkPrompt19');       
        }else{
          this.bankNameErr = false;
        }
      }
      if(type == 3){
        if(!this.bankAccount){
          this.bankAccountErr = true;
          this.bankAccountErrContent = this.$i18n.t('loginRegister.checkPrompt20');
        }else{
          this.bankAccountErr = false;
        }
      }
      if(type == 4){
        if(!this.branchName){
          this.branchNameErr = true;
          this.branchNameErrContent = this.$i18n.t('loginRegister.checkPrompt18'); 
        }else{
            this.branchNameErr = false;
        }
      }
    },
    // 修改银行信息
    SubmitBankCard(){
      if(!this.selectBankOther){
        this.selectBankNameErr = true;
        this.selectBankNameErrContent = this.$i18n.t('loginRegister.checkPrompt19');
        return;
      }
      if(this.isthStyle == 0){
        if(!this.bankCode){
          this.selectBankNameErr = false;
          this.bankCodeErr = true;
          this.bankCodeErrContent = this.$i18n.t('loginRegister.checkPrompt17'); 
          return;
        }
      }
      if(!this.bankName){
        this.selectBankNameErr = false;
        this.bankCodeErr = false;
        this.bankNameErr = true;
        this.bankNameErrContent = this.$i18n.t('loginRegister.checkPrompt19');
        return;
      }
      if(!this.bankAccount){
        this.selectBankNameErr = false;
        this.bankNameErr = false;
        this.bankCodeErr = false;
        this.bankAccountErr = true;
        this.bankAccountErrContent = this.$i18n.t('loginRegister.checkPrompt20');
        return;
      }
      if(this.isthStyle == 0 || this.isthStyle == 1){
        if(!this.branchName){
          this.selectBankNameErr = false;
          this.bankAccountErr = false;
          this.branchNameErr = true;
          this.branchNameErrContent = this.$i18n.t('loginRegister.checkPrompt18'); 
          return;
        }
      }  
      if(!this.bankImgId){
        this.selectBankNameErr = false;
        this.branchNameErr = false;
        this.bankImgIdErr = true;
        this.bankImgIdErrContent = this.$i18n.t('loginRegister.checkPrompt21');
        return;
      }
      let data = {
        bankCardImg: this.bankImgId,
        bankCode: this.bankCode,
        bankName: this.bankName,
        bankBranch: this.branchName,
        bankCardNo: this.bankAccount,
      }
      account.updateBankCardInfo(data).then((res)=>{
          if(res.data.success == 1){
              this.identityState = 0;
              this.identitySuccessful = 1;
              this.stepState = true;
              this.bankImgIdErr = false;
              this.bankCardState = true;
              this.$router.go(0);
          }else{
            this.bankImgIdErr = true;
            this.bankImgIdErrContent = res.data.message;
              console.log(res.data.message+"aaa");
          }
      }).catch(err=>{
        this.bankImgIdErr = true;
        this.bankImgIdErrContent = err.message;
        console.log(err.message+"bbb");
      })
    },

    // 显示修改银行卡
    modifyBankCard(){
      this.bankCardState = false
    },
   bankChanneShow(){   
        this.bankChanneState = false;
    }, 
  },
  mounted () {
    document.addEventListener('click', this.bankChanneShow)
  }
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
